<template>
  <main v-if="certification">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ certification.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.certifications.edit', params: { id: certification.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div>
      <p><strong>Nome</strong>: {{ certification.name }}</p>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchCertification(to.params.id)
    .then(res => next(vm => {
      vm.certification = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      certification: null,
    };
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
